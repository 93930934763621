import React, { useState, useEffect } from "react"
import { isEmpty, find } from "lodash"
// eslint-disable-next-line import/no-unresolved
import IMAGE_TRANSFORMS from "../../../../../../../static/images/config.json"
import { ShowProcessedImage } from "../image/show-image"

const { isBrowser } = require("../../../utility")
const { GetTransformedUrl } = require("../../../client")

const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

const ProcessImageTransform = ({ props }) => {
  const ImageEnvIndex = props.mediaUrl.indexOf(STAGE_ENV)
  //console.log("ProcessImageTransform ==>", props, ImageEnvIndex)
  if (ImageEnvIndex <= -1) {
    console.log("Image not in same environment!")
    return ""
  }
  const SrcCftle = props.mediaUrl.substring(ImageEnvIndex)
  // eslint-disable-next-line no-nested-ternary
  const Format = isBrowser()
    ? localStorage.getItem("GGFX-NOTWEBP")
      ? props.mediaFormat
      : "webp"
    : props.mediaFormat
  const ImageNameArray = props.transformConfigName.split(".")
  const Section = ImageNameArray.pop()
  const Field = ImageNameArray.pop()
  const Collection = ImageNameArray.join(".")
  const Transforms = Object.keys(
    IMAGE_TRANSFORMS[Collection][Field][Section].sizes,
  )
  // if (!isEmpty(props.imagetransformresult)) {
  //   const PreProcessedResult = JSON.parse(props.imagetransformresult)
  //   if (!isEmpty(PreProcessedResult[SrcCftle])) {
  //     if (!isEmpty(PreProcessedResult[SrcCftle][Format])) {
  //       processedResult = PreProcessedResult[SrcCftle][Format]
  //     }
  //   }
  // }
  // eslint-disable-next-line react/hook-use-state, react-hooks/rules-of-hooks
  const [Imgs, SetImgs] = useState({})
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // eslint-disable-next-line func-names
    ;(async function () {
      const processedResult = {}
      await Promise.all(
        Transforms.map(async (transform) => {
          if (transform) {
            const PreProcessedSrcCftle = find(
              props.preResult,
              function (pSrcCftle) {
                return pSrcCftle.src_cftle === SrcCftle
              },
            )
            let preProcessedUrl = {}
            if (!isEmpty(PreProcessedSrcCftle)) {
              preProcessedUrl = find(
                PreProcessedSrcCftle.transforms,
                function (pResult) {
                  return (
                    pResult.format === Format && pResult.transform === transform
                  )
                },
              )
            }
            /*console.log(
              "ProcessImageTransform useEffect",
              transform,
              SrcCftle,
              preProcessedUrl,
              props.preResult,
            )*/
            if (!isEmpty(preProcessedUrl)) {
              if (preProcessedUrl.url)
                processedResult[transform] = preProcessedUrl.url
            } else {
              try {
                const { data } = await GetTransformedUrl({
                  srcCftle: SrcCftle,
                  format: Format,
                  transform,
                  id: props.id,
                  field: Field,
                  contentType: Collection,
                })
                if (!isEmpty(data)) {
                  if (data.url) {
                    processedResult[transform] = data.url
                  }
                }
              } catch (error) {
                console.log(
                  "ProcessImageTransform GetTransformedUrl error",
                  error,
                )
              }
            }
          }
        }),
      )
      if (!isEmpty(processedResult)) SetImgs(processedResult)
    })()
  }, [])
  return isEmpty(Imgs) ? (
    ""
  ) : (
    <ShowProcessedImage
      {...props}
      images={Imgs}
      transforms={IMAGE_TRANSFORMS[Collection][Field][Section].sizes}
    />
  )
}

export const ImageTransform = (props) => {
  if (isEmpty(props)) {
    console.log("ImageTransform paramaters is empty!")
    return ""
  }
  // eslint-disable-next-line react/destructuring-assignment
  if (isEmpty(props.mediaUrl) || isEmpty(props.transformConfigName)) {
    console.log("ImageTransform media url or image name is empty!")
    return ""
  }
  // eslint-disable-next-line react/destructuring-assignment
  if (isEmpty(props.transformConfigName)) {
    console.log("ImageTransform config name is empty!")
    return ""
  }
  return <ProcessImageTransform props={props} />
}
